export class CommandColumnModel {
    public onClick: (...agrs) => void;
    public text: string;
    public arrayTextBinding: Array<string | number>;
    public css: object;
    public class: string;
    
    constructor(init?: Partial<CommandColumnModel>) {
        Object.assign(this, init);
    }
}
