import { Injectable } from '@angular/core';
import { ApiBaseService } from '../../../core/services/api-base.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PostCategoryModel } from '../models/post-category.model';
import { HttpResponseApiModel } from '@core/models/http-response.api-model';
import { map } from 'rxjs/operators';
import { response } from 'express';

@Injectable({
  providedIn: 'root'
})
export class PostCategoryService extends ApiBaseService {

  constructor(protected http: HttpClient) {
    super(http);
  }

  public searchListPostCategory(params: any = {}): Observable<any> {
    const url = super.revertSlugToUrl('post-category', params);
    return super.get<any>(url);
  }

  public sortPostCategory(data: { pc_id: number, current_index: number }): Observable<any> {
    const url = 'post-category/sort/';
    return super.patch(url, data);
  }

  public getDetailPostCategory(catSlug: string): Observable<PostCategoryModel> {
    const url = `post-category/${catSlug}/`;
    return super.get<HttpResponseApiModel<PostCategoryModel>>(url).pipe(
      map((data: HttpResponseApiModel<PostCategoryModel>) => data.data),
    );
  }

  public updatePostCategory(catSlug: string, data: Partial<PostCategoryModel>): Observable<PostCategoryModel> {
    const url = `post-category/${catSlug}/`;
    return super.patch<HttpResponseApiModel<PostCategoryModel>, Partial<PostCategoryModel>>(url, data).pipe(
      map((result: HttpResponseApiModel<PostCategoryModel>) => result.data)
    );
  }

  public removePostCategory(catSlug: string): Observable<any> {
    const url = `post-category`;
    return super.delete(url, catSlug);
  }

  public createPostCategory(data: PostCategoryModel): Observable<PostCategoryModel> {
    const url: string = `post-category`;
    return super.post<HttpResponseApiModel<PostCategoryModel>, PostCategoryModel>(url, data).pipe(
      map((result: HttpResponseApiModel<PostCategoryModel>) => result.data)
    );
  }
}
