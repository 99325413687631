export class ColumnModel {
    public name: string;
    public type: string;
    public visible: boolean;
    public sortable: boolean;
    public css: any;
    public class: string;
    public canHover: boolean;
    public displayName: string;
    public defaultValue: string;

    constructor(init?: Partial<ColumnModel>) {
        Object.assign(this, init);
    } 
}